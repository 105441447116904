#logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bigLogo {
  width: 320px;
}

.loginImage {
  width: 360px;
}

.labelLogin {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  opacity: 0.5;

  margin-right: 14rem;
}

.forgot-password {
  width: 150px;
  font-size: 13px;
  color: #1b4925;

  transition: color 0.2s;

  display: flex;
  margin: 5px 7px;
}

.forgot-password:hover,
.forgot-password:focus {
  color: #90b303;
}

.login-error-message {
  color: red;
  font-size: 12px;
  margin-top: 2rem;
}

.login-message {
  background-color: rgb(150, 218, 132) !important;
  /* font-size: 12px; */
  margin-top: 2rem;
  padding: 10px;
}

#user-password,
#buttons,
#logo {
  text-align: center;
  align-self: center;
  /* border: dashed 1px chartreuse; */
}

#user-password {
  width: 300px;
  margin-top: 2rem;
}

#buttons {
  margin-top: 40px;
}

.btn,
.btn-link {
  border-radius: 5px;
}

#app {
  /* background: url("../../assets/fundo-login.png"); */
  background-color: #d9d9d9;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* width: 100%; */
  height: 100vh;
}

#container {
  width: 470px;
  height: 450px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  margin-top: 80px;

  border-radius: 5%;
  box-shadow: 0px 2px 20px rgb(66, 66, 66);

  background-color: rgb(254, 253, 253);
}

@media (max-width: 1680px) {
  #app {
    background-size: cover;
  }
}

@media (max-width: 768px) {
  #app {
    background-size: cover;
  }

  #container {
    width: 400px;
    height: 460px;
  }
}
