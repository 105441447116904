body {
  font-family: "Roboto Slab", serif;
  font-style: normal;
  color: #00302c;
  margin: 0 auto;
  text-align: center;
  /* max-width: 1300px; */
}

input[type="radio" i] {
  cursor: pointer;
}

input:focus {
  outline: none;
  border-bottom: 1px solid #90b303;
  cursor: text;
}

input,
select {
  border: none;
  background: none;
  border-bottom: #00302c solid 1px;
  width: 90%;
  transition: border 0.2s;

  color: #90b303;
  margin-bottom: 1rem;

  -webkit-text-fill-color: #00302c;
}

select {
  color: #00302c;
}

select:focus,
select:hover {
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid #90b303;
}

#buttons button,
.footer button {
  width: 110px;
  height: 35px;
  background: #90b303;
  transition: background 0.3s;
  cursor: pointer;
  border: none;
  color: #ffffff;
  font-weight: 500;
}

#buttons button:hover,
#buttons button:focus,
.footer button :hover,
.footer button :focus {
  background: #71a404;
}

.btn {
  padding: 7px 15px;

  background: #90b303;

  transition: background 0.3s;

  cursor: pointer;

  border: none;
  color: #ffffff;
  text-decoration: none;

  font-family: "Roboto Slab", serif;
  font-size: 14px;
  margin: 0 10px;
}

.btn:focus,
.btn:hover,
.btn-link:focus,
.btn-link:hover {
  background: #71a404;
}

.btn-link {
  padding: 8px 18px;

  background: #90b303;

  transition: background 0.3s;

  cursor: pointer;

  border: none;
  color: #ffffff;
  text-decoration: none;

  font-family: "Roboto Slab", serif;
  font-size: 14px;
  margin: 0 10px;
}

.btn-email {
  margin-top: 3rem;
}

.MuiSvgIcon-root {
  color: #00302c;

  transition: color 0.3s;
}

.MuiSvgIcon-root:hover {
  color: #90b303;
}

.error-message {
  background: #c51244 !important;
  padding: 10px !important;
  border-radius: 0 !important;
  position: relative;
  box-shadow: 1px 1px 1px #aaaaaa;
  margin-top: 10px;
  color: white;
}
